.accordion {
    &.faqs {
        color: color(900) !important;

        &-button {
            &:not(.collapsed) {
                color: color(900) !important;
                background: transparent !important;
                font-size: 1em !important;
            }
            color: color(900) !important;
            font-family: font(md) !important;
        }
        &-header {
            margin-bottom: 0;
            border-radius: 20px;
            background: rgba(color(900),.05);
            overflow: hidden;
        }
        .questionMark {
            background: rgba(color(230), .1);
            font-size: 40px;
            margin-right: 10px;
            border-radius: 10px;
            padding: 5px;
        }
    }
}
.no-scrolled .navbar {
  // background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
  // background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1) !important;
  height: 150px;
}

// .bottomDesign {
//   position: absolute;
//   height: 175px;
//   width: calc(100% + 20px);
//   background: var(--base-color);
//   bottom: -90px;
//   transform: rotate(5deg);
//   z-index: 0;
//   left: -20px;
// }

.bottomDesign {
  position: absolute;
  height: 135px;
  background: rgba(0,0,0,.5);
  bottom: -1px;
  z-index: 1;
  width: 100%;
}

.frontend {
  // font-family: "DM Mono", monospace;
  line-height: 1.6rem;

  .btn-main {
    transition: all .3s;
    border: 2px solid #fff;
    background: #fff;
    color: #000;

    &:hover {
      background: transparent !important;
      color: #fff !important;
    }
  }

  section {

    .slick-next:before,
    .slick-prev:before {
      display: none !important;
    }

    .slick-arrow {
      background: #fff;
      height: 30px;
      width: 30px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 20px !important;
        color: #000;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }

  .socialMedia {
    img {
      height: 30px !important;
      width: 30px !important;
    }
  }

  &.commonPage {
    // padding-top: 80px;

    .navbar {
      height: 80px;
      background: color(50) !important;
      box-shadow: 0px 0px 10px 0px rgba(color(900), 0.2);

      .nav-link {
        color: color(900) !important;
        font-size: 15px;
      }
    }
  }

  .secondNav {
    background: color(50);
    box-shadow: 10px 0px 10px 0px rgba(color(900), 0.2);
    margin-bottom: 10px;
    border-top: 1px solid #ddd;

    .nav {
      &-link {
        color: color(900);
        border-bottom: 2px solid transparent;

        &.active {
          border-bottom-color: color(900);
        }
      }
    }
  }

  .navbar {
    background: color(50);

    &-brand {
      img {
        height: 60px;
        max-width: 80%;
        object-fit: contain;
      }
    }

    transition: all 0.3s;
  }

  .section {
    padding: 70px 0px;
  }

  .our_players {
    .lg_image>img {
      height: 100%;
      width: 100%;
      aspect-ratio: 1/1.2;
      object-fit: cover;
    }
  }

  .card {
    overflow: hidden;
    border-radius: 20px;
  }

  button.nextBtn,
  button.prevBtn {
    padding: 10px;
    font-size: 20px;
    border-radius: 60px;
    border: 0px solid;
    height: 50px;
    width: 50px;
    margin-right: 20px;
    margin-top: 20px;
    // background: color(220);
  }

  .objectCover {
    object-fit: cover;
    aspect-ratio: 1/1;
  }

  .section_heading {
    h3 {
      font-weight: 600;
      font-size: 40px;
      font-family: $display-font;
      letter-spacing: 2px;
      position: relative;
      margin-top: 50px;
      span {
        font-size: 110px;
        position: absolute;
        width: 100%;
        left: 0;
        top: -80px;
        opacity: .1;
        -webkit-text-stroke: 1px #000;
        z-index: 0;
        color: transparent;
        z-index: -1;
      }
      
      // display: flex;
      // align-items: center;
      &.bg-primary-inverse {
        background: #fff;
      }

      &.text-center {
        &:after {
          background: var(--accent-color);
          margin: 20px auto !important;
        }
      }

      &:after {
        content: "";
        display: block;
        width: 100px;
        background: var(--base-color);
        height: 6px;
        margin-top: 20px;
        transform: skewX(40deg);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .section_heading {
      h3 {
        font-size: 35px;
        span { 
          font-size: 80px;
          top: -50px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &-brand {
      img {
        height: 50px;
      }
    }

    .section_heading {
      h3 {
        font-size: 30px;
        span { 
          font-size: 70px;
          top: -40px;
        }
      }
    }

    .section {
      padding: 50px 0px;
    }
  }

  @include media-breakpoint-down(sm) {
    .section_heading {
      h3 {
        font-size: 25px;
        span { 
          font-size: 50px;
          top: -30px;
        }
      }
    }

    .section {
      padding: 35px 0px;
    }
  }
}

.scrolled .navbar {
  // background: color(50);
  height: 80px;
}

.states_circle {
  height: 150px;
  min-width: 150px;
  border: 1px solid #9c9c9c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 1/1;
  margin: 0 auto 20px;
  gap: 20px;

  @include media-breakpoint-down(lg) {
    height: 130px;
    min-width: 130px;
  }

  @include media-breakpoint-down(sm) {
    height: 200px;
    min-width: 200px;

    h1 {
      font-size: 24px;
    }
  }
}

.social-media-icons {
  a {
    display: inline-block;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: color(130);
    margin-right: 10px;
    border-radius: 160px;

    &:hover {
      background: color(220);
      color: color(50);
    }
  }
}
/* Chat */

.chatSystem {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 100000000;
    // background: red;
    height: 487px;
    bottom: 0;
    right: 0;
    flex-direction: row-reverse;
    gap: 5px;
}

.chat {
    height: 400px;
    width: 300px;
}

.chat-container {
    height: 100%;
}

/* User Bar */

.user-bar {
    height: 55px;
    background: color(210);
    color: color(50);
    padding: 0 8px;
    font-size: 24px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    .avatar {
        margin: 0 0 0 5px;
        width: 36px;
        height: 36px;

        img {
            border-radius: 50%;
            box-shadow: 0 1px 0 rgba(color(50), 0.1);
            display: block;
            width: 100%;
        }
    }

    .name {
        font-size: 17px;
        font-weight: 600;
        text-overflow: ellipsis;
        letter-spacing: 0.3px;
        margin: 0 0 0 8px;
        overflow: hidden;
        white-space: nowrap;
        width: 110px;
    }

    .status {
        display: block;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0;
    }
}


/* Conversation */

.conversation {
    height: calc(100% - 12px);
    position: relative;
    z-index: 0;
    background: #edf2f9;
    box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.3);

    &-container {
        height: calc(100% - 68px);
        box-shadow: inset 0 10px 10px -10px #000000;
        overflow-x: hidden;
        padding: 0 16px;
        margin-bottom: 5px;
    }

    &-container:after {
        content: "";
        display: table;
        clear: both;
    }
}

/* Messages */

.message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    z-index: -1;

    &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
    }

    &:first-child {
        margin: 16px 0 8px;
    }

    &.received {
        background: color(50);
        border-radius: 0px 5px 5px 5px;
        float: left;
    }

    &.received {
        .metadata {
            padding: 0 0 0 16px;
        }
    }

    &.received {
        &:after {
            border-width: 0px 10px 10px 0;
            border-color: transparent color(50) transparent transparent;
            top: 0;
            left: -10px;
        }
    }

    &.sent {
        background: #dae9ff;
        border-radius: 5px 0px 5px 5px;
        float: right;
    }

    &.sent {
        &:after {
            border-width: 0px 0 10px 10px;
            border-color: transparent transparent transparent #dae9ff;
            top: 0;
            right: -10px;
        }
    }

    .metadata {
        display: inline-block;
        padding: 0 0 0 7px;
        position: relative;
        bottom: -4px;

        .time {
            color: rgba(0, 0, 0, .45);
            font-size: 11px;
            display: inline-block;
        }

        .tick {
            display: inline-block;
            margin-left: 2px;
            position: relative;
            top: 4px;
            height: 16px;
            width: 16px;

            svg {
                position: absolute;
                transition: .5s ease-in-out;
            }

            svg {
                &:first-child {
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-transform: perspective(800px) rotateY(180deg);
                    transform: perspective(800px) rotateY(180deg);
                }

                :last-child {
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -webkit-transform: perspective(800px) rotateY(0deg);
                    transform: perspective(800px) rotateY(0deg);
                }

                &:first-child {
                    -webkit-transform: perspective(800px) rotateY(0);
                    transform: perspective(800px) rotateY(0);
                }

                &:last-child {
                    -webkit-transform: perspective(800px) rotateY(-179.9deg);
                    transform: perspective(800px) rotateY(-179.9deg);
                }
            }
        }
    }
}

/* Compose */

.conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 50px;
    width: 100%;
    z-index: 2;
}

.conversation-compose div,
.conversation-compose input {
    background: color(50);
    height: 100%;
}

.conversation-compose .emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(50);
    border-radius: 5px 0 0 5px;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 48px;
}

.conversation-compose .input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 16px;
    margin: 0;
    outline: none;
    min-width: 50px;
}

.conversation-compose .photo {
    flex: 0 0 auto;
    border-radius: 0 0 5px 0;
    text-align: center;
    position: relative;
    width: 48px;
}

.conversation-compose .photo:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent color(50);
    border-style: solid;
    position: absolute;
    width: 0;
    height: 0;
    content: "";
    top: 0;
    right: -10px;
}

.conversation-compose .photo i {
    display: block;
    color: #7d8488;
    font-size: 24px;
    transform: translate(-50%, -50%);
    position: relative;
    top: 50%;
    left: 50%;
}

.conversation-compose .send {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0;
    position: relative;
    outline: none;
}

.conversation-compose .send .circle {
    background: color(210);
    border-radius: 50%;
    color: color(50);
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.floatingChatBbl {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100000;
    // background: color(210);
    padding: 12px;
    box-shadow: 10px 10px 20px 0px rgba(color(900), 0.3);
    border-radius: 100px;
    font-size: 35px;
    // color: color(50);
    cursor: pointer;
}
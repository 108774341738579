.articleDetailsPage {
    .clamp2 {
        margin-bottom: 10px;
        display: inline-block;
        p {
            
            &:last-child{
                margin-bottom:inherit !important;
            }
        }
        @include text-clamp($lines: 3, $line-height: false);
        * {
            font-size: inherit;
            margin:0px;
            font-weight: inherit;
        }a,
        img {
            display:none
        }
    }
    .blogImg {
        .card-body{
        &>img {
            width: 100%;
            height: 600px;
            object-fit: cover;
        }
    }
        .article_content {
        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
        @include media-breakpoint-down(lg) {

            img {
                height: 400px;
            }

        }

        @include media-breakpoint-down(md) {

            img {
                height: 300px;
            }
        }

        @include media-breakpoint-down(sm) {

            img {
                height: 250px;
            }

        }
    }

    .blogImgSm {
        img {
            height: 70px;
            width: 70px;
            object-fit: cover;
            border-radius: 10px;
        }
        @include media-breakpoint-down(lg) {
            img {
                height: 50px;
                width: 50px;
   
            }
        }
        @include media-breakpoint-down(md) {
            img {
                height: 35px;
                width: 35px;
   
            }
        }
    }
    
}






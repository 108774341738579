.tipsHolder {
    .modal-header {
        z-index: 10;
    }

    .sportimg {
        max-width: 100%;
        // margin-top: -30px;
        margin-bottom: 10px;
        // width: 400px;
    }

    .slick-dots li button:before {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background-color: color(230);
    }

    .slick-next,
    .slick-prev {
        font-size: 1rem;
        color: #000;
        position: relative;
    }

    .slick-next:before,
    .slick-prev:before {
        display: none;
    }

    .slick-slider {
        padding-bottom: 20px;
    }

    .modal {
        &-body {
            padding-bottom: 50px;
        }
    }
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    span {
        display: block;
        width: 50px;
        height: 2px;
        background: color(140);
    }
    .active+span {
        display: block;
        width: 50px;
        height: 2px;
        background: #0081c2;
    }
    .step {
        border: 2px solid color(140);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        background: color(50);
        border-radius: 100%;
    
        &.active {
            border-width: 0px;
            background: #0081c2;
            color: #fff;
            font-weight: 700;
        }
    }
}
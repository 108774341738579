.slider-dots-active > li {
  // display: none;
  opacity: 0;
  transition: opacity 800ms ease-in-out;
  position: absolute;
  bottom: 3.5rem;

  &.slick-active {
    // display: block;
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 4px;
  border: 0;
  border-radius: 20px;
  // display: none;

  &::-webkit-progress-bar {
    background-color: var(--accent-color);
  }

  &::-webkit-progress-value {
    transition: all 1s ease;
    background-color: var(--text-color);
  }

  // @include media-breakpoint-down(lg) {
  //   display: block;
  // }
}

.mainSlider {
  width: 100%;
  overflow: hidden;
  background: var(--color-primary);

  &_slide {
    position: relative;
    margin-bottom: -6px;
    display: flex;
    video {
      width: 100%;
      height: calc(100vh - 10px);
    }

    img {
      // width: 50%;
      height: calc(100vh);
      margin-left: auto;
      // height: 750px;
      object-fit: cover;
    }
  }

  &_slideinfo {
    // position: absolute;
    // padding: 10px;
    // top: 0;
    // left: 0;
    margin-top: 70px;
    height: 100%;
    width: 100%;
    // background: rgba(color(900), 0.5);
    display: flex;
    padding-inline: 80px;
    // align-items: center;
    justify-content: center;
    flex-direction: column;

    // text-align: center;
    color: color(50);

    h3 {
      font-family: $display-font;
      letter-spacing: 2px;
      font-size: 40px;
      font-weight: 700;
    }

    p {
      font-size: 20px;
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(xl) {
    &_slideinfo {
      h3 {
        font-size: 50px;
      }

      p {
        font-size: 22px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &_slideinfo {
      h3 {
        font-size: 40px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &_slideinfo {
      h3 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &_slideinfo {
      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        margin-top: 0;
        line-height: 1.2rem !important;
      }
    }
  }
}

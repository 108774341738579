.productCard {
    position: relative;

    &:hover {
        .cardAction {
            opacity: 1;
            background: rgba(color(900), 0.3);
            pointer-events: auto;
            a {
                font-size: 2em;
            }
        }
    }

    .cardAction {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(color(900), 0);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all .3s;
        pointer-events: none;

        a {
            display: block;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1em;
            border-radius: 100%;
            color: color(50);
            transition: all .3s .3s;
        }
    }

    overflow: hidden;

    .card-body {
        &>div {
            position: relative;
        }

        img {
            height: 250px;
            object-fit: cover;
        }
    }

    .discountBar {
        // background: color(230);
        color: #fff;
        padding: 6px;
        margin: 10px -1rem;
        font-size: 14px;
        font-family: font(bd);
        rotate: 45deg;
        position: absolute;
        top: 17px;
        right: -18px;
        width: 160px;
        z-index: 1000;
    }

    h5 {
        font-family: font(bd);
        font-size: 18px;
        margin-top: 10px;
        min-height: 50px;
        line-height: 25px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        display: inline-block;
        // margin: 0rem -1rem 0rem -1rem !important;
        // padding: 8px 4px 8px 1rem;
        // color: #fff;
        font-size: 20px;
        position: relative;

        // &:after {
        //     content: "";
        //     display: block;
        //     height: 100%;
        //     width: 30px;
        //     transform: skew(25deg, 0deg);
        //     position: absolute;
        //     top: 0;
        //     right: -13px;
        //     background: #e91e63;
        // }
        span {
            position: relative;
            z-index: 1;
        }

        .h6 {
            font-family: font(bd);
        }
    }
}

.shopSlider {
    padding: 0;
    margin: 0px -1.5rem;

    &_slide {
        position: relative;

        img {
            height: 500px;
            object-fit: cover;
            object-position: center;
        }
    }

    &_slideinfo {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(color(900), .4);

        h3 {
            font-weight: 700;
            font-size: 44px;
            color: color(50);
        }

        p {
            font-size: 20px;
            color: color(50);
            text-align: center;
            padding: 0 10%;
            line-height: 25px;
            margin-top: 0px;
        }

    }

    @include media-breakpoint-down(md) {
        &_slide {
            img {
                height: 300px;
            }
        }
    }

}

.productSlider {
    .slick-slide {
        img.slide {
            height:100%;
            width: 100%;
            aspect-ratio: 1/1;
        }
    }
    .slideThumb {
        height: 100% !important;
        aspect-ratio: 1/1;
        border: 1px solid rgba(color(900), .2);
    }
}
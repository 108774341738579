footer {
  background: color(50);
  padding: 20px 0px;
.socialMedia {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px 30px;
    width: auto;
    box-shadow: none;
    padding: 0px;
    margin-bottom: 10px;
    z-index: 0;
    @include media-breakpoint-down(md) {
    .socialLabel {
      display: none;
    }
  }
    a {
      margin: 0;
    }
    &:hover {
      margin-bottom: 10px;
      padding: 0;
    }
}
}
.btn {
    line-height: normal;
    font-family: font(md);
    font-size: 1em;
    padding: 7px 15px;
    border-radius: 5px;
    min-width: fit-content;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 50%;
        background: rgba(color(50), .3);
        position: absolute;
        top: 0;
        left: -50%;
        transition: all .7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    &.loading {
        &:before {
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            background: url(https://64.media.tumblr.com/4aac8d29242aca59360950688598e5d3/tumblr_om086g92Eq1runoqyo6_250.gif);
            float: left;
            background-position: center;
            background-size: contain;
            filter: brightness(10) saturate(0) contrast(2);
            margin-right: 5px;
        }
    }

    @include transition(all 0.4s);

    @include media-breakpoint-down(xl) {
        font-size: 14px;
        padding: 8px 12px;
    }

    &-sm {
        padding: 8px 12px;
        font-size: 12px;
    }

    &:focus {
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    &-primary {
        border-color: var(--base-color);
        background: var(--base-color) !important;
        color: var(--text-color) !important;

        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus {
            border-color: transparent;
            background: rgba($color: color(235), $alpha: 0.9);
            color: color(50);
            outline: none;
            box-shadow: none !important;

            &:after {
                width: 50%;
                left: 100%;
            }
        }

        &-outline {
            border-color: color(235);
            color: color(50);
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: color(235);
                color: color(900);
                background-color: color(235);
            }
        }
    }

    &-secondary {
        border-color: color(900);
        color: color(50);
        background-color: color(900);

        &:hover,
        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active:focus {
            border-color: transparent;
            background: rgba($color: color(900), $alpha: 0.9);
            color: color(50);
            outline: none;
            box-shadow: none !important;
        }

        &-outline {
            border-color: color(900);
            color: color(50);
            background-color: transparent;

            &:hover,
            &:focus,
            &:active {
                border-color: color(900);
                color: color(50);
                background-color: color(900);
            }
        }
    }
}
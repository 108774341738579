.star_rating {
    &>* {
        font-size: 1.1rem;
    }
    &--fill {
        fill: color(450);
    }
    &--empty {
        fill: color(140);
    }
    .size_lg {
        font-size: 3rem;
    }
}
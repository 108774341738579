.dropdown {
    &.status_dropdown {
        button {
            font-size: .9em;
            font-weight: bold;

            &.btn-warning {
                background: color(450) !important;
                color: color(900) !important;
            }

            &.btn-danger {
                background: color(350) !important;
                color: color(50) !important;
            }

            &.btn-success {
                background: color(600) !important;
                color: color(50) !important;
            }

            &.btn-primary {
                background: var(--base-color) !important;
                color: var(--text-color) !important;
            }
        }
    }

    .btn {
        background: color(120);
        border-width: 0px;
        color: color(800) ;
        box-shadow: 0px 2px 6px rgba(color(800), 0.2);
        padding: 3px 6px;
        font-size: 14px;

        &:hover {
            box-shadow: 0px 2px 6px rgba(color(800), 0.4) !important;
        }

        &.btn-warning {
            background: color(450) !important;
            color: color(900) !important;
        }

        &.btn-danger {
            background: color(350) !important;
            color: color(50) !important;
        }

        &.btn-success {
            background: color(600) !important;
            color: color(50) !important;
        }

        &.btn-primary {
            background: var(--base-color) !important;
            color: var(--text-color) !important;
        }

        &.btn-info {
            background: color(700) !important;
            color: color(900) !important;
        }
        
    }

    &-item {
        font-family: font(rg);
        padding: 10px 15px;
        font-size: .9em;
        color: color(150) !important;

        svg {
            margin-right: 10px;
        }

        &:focus {
            background: color(110);
        }

        &:active {
            background: color(110);
        }

        &:hover {
            background: color(110);
        }
    }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.tournament-bracket {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 0px;
  padding-bottom: 30px;
  overflow: auto;
  height: auto;

  &__round {
    display: block;
    margin-left: -3px;
    flex: 1;

    &:first-child {
      ul {
        padding-left: 0px;
      }
    }

    &:last-child {

      .tournament-bracket__item::after,
      .tournament-bracket__item::before {
        display: none !important;
      }
    }

    &:first-child {

      .tournament-bracket__item::after,
      .tournament-bracket__item::before {
        display: none;
      }
    }
  }

  &__match {
    flex-direction: column;

    &:after {
      display: none;
    }
  }

  &__round-title {
    color: #9e9e9e;
    font-size: 0.95rem;
    font-weight: 400;
    text-align: center;
    font-style: italic;
    margin-bottom: 0.5em;
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: center;
    height: 100%;
    min-height: 100%;
    border-bottom: 1px dashed #e5e5e5;
    padding-bottom: 2em;
    margin-bottom: 2em;
    transition: padding 0.2s ease-in-out, margin 0.2s ease-in-out;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;

  }

  .tournament-bracket__round:last-child .tournament-bracket__list {
    border: 0;
  }

  .tournament-bracket__round:first-child .tournament-bracket__item {
    padding-left: 0;
  }

  .tournament-bracket__round:last-child .tournament-bracket__item {
    padding-right: 0;
  }


  &__item {
    padding: 0.5em 0;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 280px;
    width: 100% !important;
    // height: 1px;
    width: 30px;


    &::after {
      content: "";
      background: rgba(color(900), 0.2);
      display: block;
      position: absolute;
      width: 1px;
      height: calc(50% + 2px);
      position: absolute;
      top: calc(25% + 0px);
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 30px;
      background: rgba(color(900), 0.2);
    }
  }


  &__table {
    width: 100%;
  }

  &__caption {
    font-size: 0.8rem;
    color: #BDBDBD;
    font-weight: 300;
    padding-bottom: 0.75em;
  }

  &__team {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__country {
    font-size: 0.95rem;
    display: flex;
    margin-top: 0.5em;
    align-items: center;
  }

  &__code {
    padding: 0 0.5em;
    color: #212121;
    font-weight: 600;
    text-transform: uppercase;
    border: 0;
    text-decoration: none;
    cursor: help;
    transition: padding 0.2s ease-in-out;
  }

  &__score {
    display: flex;
    background: #0081c2;
    margin-left: 4px;
    min-width: 26px;
    text-align: center;
    height: 26px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(color(900), .2);
  }

  &__match {
    display: flex;
    width: 100%;
    background-color: #f4f6fd;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 5px 0px rgba(color(900), 0.3);
    outline: none;
    cursor: pointer;
    transition: padding 0.2s ease-in-out, border 0.2s linear;
    // padding: .5rem;

    &>.d-flex {
      padding: 5px;
      border: 1px solid transparent;
      border-radius: 5px 5px 0px 0px;

      &:first-child {
        border-bottom: 1px solid rgba(color(900), 0.2);
        border-radius: 5px 5px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 0px 5px 5px;
      }
      &>:hover {
        border-color: #2196F3;
      }
      img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        border-radius: 5px;
        border: 1px solid rgba(color(900), 0.2);
      }
    }

    &:after {
      content: "";
      display: block;
      height: 1px;
      width: 30px;
      background: rgba(color(900), 0.2);
      position: absolute;
      right: -29px;
      top: 50%;
    }
  }
}



.tournament-bracket__match:focus {
  border-color: #2196F3;
}



.tournament-bracket__content {
  display: flex;
}


.tournament-bracket__content .tournament-bracket__team:first-child {
  width: 50%;
  order: 0;
  text-align: right;
}

.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__country {
  order: 2;
  justify-content: flex-end;
}

.tournament-bracket__content .tournament-bracket__team:first-child .tournament-bracket__score {
  order: 0;
}

.tournament-bracket__content .tournament-bracket__team:last-child {
  width: 50%;
  order: 2;
  text-align: left;
}

.tournament-bracket__content .tournament-bracket__team:last-child .tournament-bracket__code {
  order: 1;
}


.tournament-bracket__team:first-child .tournament-bracket__score {
  flex-direction: row-reverse;
  padding-left: 0.75em;
}

.tournament-bracket__team:last-child .tournament-bracket__score {
  padding-right: 0.75em;
}

.tournament-bracket__number {
  display: inline-block;
  padding: 0.2em 0.4em 0.2em;
  border-bottom: 0.075em solid transparent;
  font-size: 0.95rem;
  background-color: #F5F5F5;
  border-color: #dddddd;
}

.tournament-bracket__team--winner .tournament-bracket__number {
  background-color: #FFF176;
  border-color: #fad674;
}

.tournament-bracket__medal {
  padding: 0 0.5em;
}

.tournament-bracket__medal--gold {
  color: #FFD700;
}

.tournament-bracket__medal--silver {
  color: #C0C0C0;
}

.tournament-bracket__medal--bronze {
  color: #CD7F32;
}
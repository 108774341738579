.coachDetails {
    .profileCard {
        overflow: hidden;
        &-bg {
            background-image: url($imageurl + "/bg.jpg");
            padding-top: 100px;
            background-size: cover;
            background-position: center;
            .profile {
                margin-bottom: -60px;
                border: 5px solid color(50);
                position: relative;
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                }
            }
        }
        &-title {
            margin-top: 60px;
            font-size: 22px;
            font-family: font(md);
        }
        small {
            font-size: 14px;
        }
        &-sports {
            margin-top: 15px;
            .badge {
                margin-right: 5px;
                margin-bottom: 5px;
                padding: 7px 15px;
                font-size: 14px;
                font-family: font(lt);
                background: rgba(color(220), 0.1) !important;
                color: color(900);
                // border: 1px solid color(220);
            }
        }
    }
}

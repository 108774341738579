.font-rg {
  font-family: font(rg);
}

.font-md {
  font-family: font(md);
}

.font-sb {
  font-family: font(sb) !important;
}

.font-bd {
  font-family: font(bd) !important;
}

.font-ebd {
  font-family: font(ebd) !important;
}

.font-lt {
  font-family: font(lt);
}

// links
a {
  @include transition(all 0.5s);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// radius
.brr-16 {
  border-radius: 16px;
}

.brr-24 {
  border-radius: 24px;
}

// colors
.bg-900 {
  background: color(900);
}

.badge {
  padding: 6px 8px;
  display: inline-block;
  box-shadow: 0px 2px 6px rgba(52, 58, 64, 0.2);
  font-size: 1em;
  font-weight: var(--bs-btn-font-weight);

  &.bg {
    &-warning {
      background: color(450) !important;
      color: color(900) !important;
    }

    &-danger {
      background: color(350) !important;
      color: color(50) !important;
    }

    &-success {
      background: color(600) !important;
      color: color(50) !important;
    }

    &-primary {
      background: color(210) !important;
      color: color(50) !important;
    }
  }
}

// Padding
.p-80 {
  padding: 80px;

  @include media-breakpoint-only(md) {
    padding: 80px 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 60px 24px;
  }
}

.py-80 {
  padding: 80px 0;

  @include media-breakpoint-down(xl) {
    padding: 60px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 40px 0;
  }
}

.mb-80 {
  margin-bottom: 80px;

  @include media-breakpoint-down(xl) {
    margin-bottom: 56px;
  }
}

// heading
.h-24 {
  font-size: 24px;
  line-height: 32px;

  @include media-breakpoint-down(xl) {
    font-size: 20px;
    line-height: 26px;
  }
}

.bg-fixed {
  background-attachment: fixed;
}
.dashboardPage {
    &.mainDashboard {

        .card {
            min-height: 235px;
        }
    }

    padding: 0 1.5rem;

    .stepFinished {
        text-decoration: line-through;

        .flex-grow-1,
        canvas {
            opacity: .5;
            filter: grayscale(1);
        }
    }

    .quickLinks {
        .btn {
            color: color(900);
            background: color(50);
            border: 2px solid color(220);
            text-align: left;
            padding: 5px 10px;
            border-radius: 10px;
            transition: all .3s;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                padding-left: 10px;
            }

            &:hover {
                color: color(50);
                background: color(220);

                svg {

                    background: color(50);
                    padding: 8px;
                    color: color(220);

                }
            }

            svg {

                border-radius: 100%;
                height: 40px;
                min-width: 40px;
                width: 40px;
                background: color(220);
                padding: 8px;
                color: color(50);

            }

        }
    }

    .stats {
        h6 {
            color: color(850);
        }

        .card-body {
            // border-top: 2px solid;
        }

        .state-icon {
            font-size: 40px;
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px !important;
            border-radius: 100px;

            &-green {
                color: color(650);
                background: rgba(color(650), 0.1);
            }

            &-red {
                color: color(350);
                background: rgba(color(350), 0.1);
            }
        }

        .arrow {
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 40px;
            font-size: 15px;
            margin-right: 10px;

            &-up {
                background-color: rgba(color(650), 0.2);
                color: color(650);
            }

            &-down {
                background-color: rgba(color(350), 0.2);
                color: color(350);
            }
        }
    }

    .quicklinkHolder {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;

        * {
            text-align: center;
        }

        svg {
            font-size: 26px;
        }
    }

    .quickStart {
        &-items {
            position: relative;

            h5 {
                font-family: font(bd);
                color: color(800);
                margin-bottom: 5px;
                font-size: 16px;
            }

            span {
                color: color(150);
                display: block;
            }

            padding: 20px 5px;
            border-bottom: 1px solid color(110);
            display: flex;
            align-items: center;
        }

        &-icon {
            height: 50px;
            width: 50px;
            border-radius: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: color(110);
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .quickStart_bday {

        .slick-next:before,
        .slick-prev:before {
            color: var(--base-color);
        }

        button.slick-arrow {
            z-index: 1;
            color: #000 !important;

            &.slick-prev {
                left: 0px;
            }

            &.slick-next {
                left: auto;
                right: 0;
            }
        }

        &-items {
            position: relative;

            h5 {
                font-family: font(bd);
                color: color(800);
                margin-bottom: 0px;
                font-size: 16px;
            }

            span {
                color: color(150);
                display: block;
            }

            padding: 0px 5px;
            text-align: center;
        }

        &-icon {
            height: 45px;
            width: 45px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ededed;
            overflow: hidden;
            margin: 0px auto 5px;
            background: color(110);

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .quickLinks.card,
    .draggableForm .card,
    .profileCard,
    .bankcard,
    .categoryPlayedCard,
    .stats,
    .propertiesCard {
        min-height: 0 !important;
    }


    .bday_card {
        min-height: 207px !important;
    }


    @include media-breakpoint-down(md) {
        padding: 0 1rem;

        .stats small,
        .quicklinkHolder * {
            font-size: 13px;

        }
    }

}
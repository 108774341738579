.navbar {
    .nav {
        &-tabs {
            border-bottom: 1px solid color(140);
            background: color(50);

            .nav-link {
                color: color(235) !important;
                font-weight: bold;

            }
        }

        &-link {
            border-top: 0px solid !important;
            border-left: 0px solid !important;
            border-right: 0px solid !important;
            border-bottom: 2px solid transparent !important;

            &.active {
                // border-color: color(250) !important;

            }
        }
    }
}

.nav {
    &-tabs {
        border-bottom: 1px solid color(140);
        // background: color(50);

        color: color(900) !important;

        .nav-link {
            // font-weight: bold;
            color: color(150);

            &.active {
                background: var(--base-color);
                color: var(--text-color);

                // color: #fff
            }
        }

        @include media-breakpoint-down(md) {
            button {
                font-size: .8rem;
                padding: 5px 7px;
            }
        }
    }
}
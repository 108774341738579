.card {
    border-color: color(110);
    border-radius: 0.375rem;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    // overflow: auto;
    margin-bottom: 15px;
    color: color(860);
    border-width: 0px;

    &-header {
        background: transparent;
        padding: 0px 10px 10px 0px;
        font-weight: bold;
        font-size: 1em;
        margin-bottom: 15px;
        border-bottom: 1px solid transparent !important;
    }

    &-title {
        font-size: .9em;
        margin-bottom: 10px;
        color: color(230);
        font-family: font(bd);
    }
    &-body {
        padding: 15px;
    }
    &-footer {
        background: color(50);
        border-top: 1px solid color(110);
        padding: 15px;
    }
    @include media-breakpoint-down(md) {
        &-body {
            padding: 15px;
        }
    }
}

.modal {
  z-index: 10000001;

  &-footer,
  &-header {
    border: 0px solid !important;
    padding: 20px 40px 10px;
  }

  &-title {
    margin-bottom: 0;
    font-size: 20px !important;
    font-weight: 700 !important;

    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 200px;
      border-radius: 10px;
      position: absolute;
    }
  }

  &-backdrop {
    z-index: 10000000 !important;
  }

  &-body {
    padding: 15px 40px;
  }

  @include media-breakpoint-down(xl) {

    &-header,
    &-footer,
    &-body {
      padding: 15px 30px;
    }
  }

  @include media-breakpoint-down(lg) {

    &-header,
    &-footer,
    &-body {
      padding: 15px 20px;
    }
  }
}
.auth {
    .checkicon {
        background: rgba(color(50), 0.15);
        color: color(50);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border-radius: 100%;
        margin-right: 10px;

        svg {
            background: rgba(color(900), 0) !important;
        }
    }



    &-right {
        // background-image: url("https://img.freepik.com/free-photo/tennis-racket-new-tennis-ball-freshly-painted-tennis-court_146671-18857.jpg?w=1024");
        background-size: cover;
        background-position: center;

        height: 100%;
        position: sticky !important;
        top: 0;

        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            // background-color: rgba(color(230), 0.85);
        }

        &>* {
            position: relative;
            z-index: 1;
        }
    }

    h2 {
        color: color(50);
        font-size: 50px;
        margin-bottom: 20px;
        font-family: font(lt);
    }

    .list-group {
        li {
            background: transparent !important;
            color: color(50);
        }
    }

    @include media-breakpoint-down(xl) {
        h2 {
            font-size: 40px;
        }
    }

    @include media-breakpoint-down(lg) {
        h2 {
            font-size: 30px;
        }

    }

    @include media-breakpoint-down(md) {
        h2 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    @include media-breakpoint-down(sm) {
        background: #FFF;
    }

    @include media-breakpoint-down(lg) {
        background: #fff !important;
        padding: 10px 40px;

        .inputHolder {
            margin-bottom: 30px !important;
            // padding: 0px 40px;
            position: relative;

            
            button {
                height: 40px;
            }
            .react_select div[class*=-control],
            .form-control {
                height: 40px;
                border-radius: 0px;
                border-width: 0px 0px 2px 0px !important;
                border-color: #ddd;
                transition: .3s all;

                &:hover {
                    border-color: #000;
                }
            }
        }
    }

}
.navbar {
	padding: 15px;
	// backdrop-filter: blur(5px);
	// background: rgba(color(900), 0) !important;

	// .nav-link {
	//     color: color(50);
	//     font-size: 15px;
	// }
	svg {
		font-size: 18px;
	}

	@include media-breakpoint-down(xl) {
		.navbar-nav {
			// background: color(50);

			display: flex;
			flex-direction: row;
			align-items: center;

			a {
				padding: 10px;
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 3px 0px;
	}

	.dropdown-menu {
		position: absolute;
		right: 0;
		left: auto;
		z-index: 10001;
	}
}

.svgLogo {
	svg {
		// height: 50px;
		// width: 170px;
	}

	span.logoText {
		color: color(50);
		font-weight: bold;
		font-size: 34px;
		margin-left: 6px;
		transition: all 0.3s;
	}
}

.scrolled .navbar {
	.nav-link {}

	span.logoText {
		// color: color(900);
	}
}

.sideBarToggle_,
.sideBarToggle {
	font-size: 20px;
	display: inline-block;
	margin-right: 20px;
}

.globalSearch {
	position: relative;

	input {
		border-radius: 50px;
		width: 500px;
		font-size: 14px;
		padding-left: 35px;
	}

	svg {
		position: absolute;
		top: 10px;
		left: 10px;
		font-size: 16px;
	}

	@include media-breakpoint-down(xl) {
		.collapse:not(.show) {
			display: flex !important;
		}

		input {
			border-radius: 50px;
			width: 100% !important;
			font-size: 14px;
			padding-left: 35px;
		}
	}

	@include media-breakpoint-down(md) {
		position: absolute;
		top: 50px;
		width: 100%;
		left: 0;
		// box-shadow: 10px 7px 10px 0px rgba(0, 0, 0, 0.1);
		border-top: 1px solid #ddd;

		input {
			border-width: 0px;
			border-radius: 0px;
		}
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react_select {
	.disable {
		pointer-events: none;
		opacity: 0.8;
	}

	div[class*="-control"] {
		// height: 35px !important;
		border-radius: 6px;
		min-height: 35px;
		// background: transparent;

		&+div[class*="-menu"] {
			z-index: 1000;
			position: absolute;
		}
	}
}

// box-shadow: 0 0 21px 0 rgba(color(50), 0.5);
.loader {
    &_sm {
        border: 3px solid rgba(255,255,255,.4);
        border-top: 3px solid var(--text-color);
        border-radius: 50%;
        width: 18px;
        height: 18px;
        animation: spin 2s linear infinite;
        margin: 0 auto;
    }


    @keyframes loaderanim {
        from {
            transform: rotateY(0deg);
        }

        to {
            transform: rotateY(360deg);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    img {
        height: 70px;
        max-width: 100px;
        width: auto;
        margin-bottom: 10px;
        animation: loaderanim 3s linear infinite;

    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color(50);
    position: fixed;
    perspective: 200px;
    z-index: 10000000;
    /* Use a valid background color here */
}
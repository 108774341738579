.ltTable th {
    text-transform: capitalize;
}

.table {

    // &.no-border {
    tr {
        &:hover {
            td {
                background: color(100);
                --bs-table-accent-bg: transparent !important;
            }
        }
    }
    .disabledAttendanceDay {
        pointer-events: none;
        background: #f7f7f7 !important;
    }
    th,
    td {
        transition: all 0.3s;
        vertical-align: middle;
        color: #5e6e82;

        position: relative;

        padding: 0.5rem;
        white-space: nowrap;

        &.ellipsis {
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dropdown-toggle::after {
            display: none;
        }
    }

    th {
        background: #edf2f9;
        border-bottom: 0px;
        color: color(800);
        padding-top: .6em;
        padding-bottom: .6em;
        font-size: 1em;

        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }
    }

    td {
        transition: all 0.3s;
        // border-bottom: 0px solid !important;
        vertical-align: middle;
        color: #5e6e82;

        .d-flex {
            align-items: center;
        }

        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }
    }


    &-bottom {
        display: flex;
        align-items: center;

        .disable {
            pointer-events: none !important;
            opacity: .5;
        }

        .col>span {
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                background-color: color(100);
            }
        }

        &>.text-end {
            max-width: 92px;
        }

        &>.d-flex {
            display: flex !important;
        }

        @include media-breakpoint-down(sm) {
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            // text-align: center;

            // div {
            //     display: block;

            // }
            &>.text-end {
                max-width: 92px;
            }

            &>.d-flex {
                display: block !important;
            }
        }
    }

    &-responsive {
        min-height: 250px;
    }

}
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@import url("./theme.css");

  html {
    scroll-behavior: smooth;
  }

body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #000 !important; /* Change '#000' to your desired text color */
}
.bg-primary {
  background-color: var(--base-color) !important;
  color: var(--text-color) !important;
}
.fill-primary {
  fill: var(--base-color) !important;
}
.bg-primary-inverse {
  background: var(--text-color) !important;
  color: var(--base-color) !important;
}

.icon-primary {
  filter: var(--icon-color) !important;
}

.icon-primary-inverse {
  filter: var(--icon-color-inverse) !important;
}


.text-primary {
  color: var(--text-color) !important;
}

.text-primary-inverse {
  color: var(--base-color) !important;
}

.bg-text {
  background: var(--text-color) !important;
  color: var(--text-color-inverse) !important;
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-between {
    @apply flex items-center justify-between;
  }

  .flex-start {
    @apply flex items-center justify-start;
  }

  /* .card-wrapper {
		@apply bg-light-900 dark:dark-gradient shadow-light-100 dark:shadow-dark-100;
	}*/

  .btn-main {
    @apply bg-text w-full rounded-none px-20 py-4 font-bold uppercase lg:w-auto !important;
  }

  /*	.btn-secondary {
		@apply bg-light-800 dark:bg-dark-400 !important;
	}

	.btn-tertiary {
		@apply bg-light-700 dark:bg-dark-300 !important;
	} */
}

.no-focus {
  @apply focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
}

.active-theme {
  filter: invert(53%) sepia(98%) saturate(3332%) hue-rotate(0deg) brightness(104%) contrast(106%) !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@layer utilities {
  .h1-bold {
    @apply text-[30px] font-bold leading-[42px] tracking-tighter;
  }

  .h3-home-heading {
    @apply font-epilogue text-[40px] font-bold;
  }

  .p-home {
    @apply text-justify font-heebo text-[16px];
  }

  .button-home {
    @apply flex items-center gap-5 self-baseline rounded-full border border-solid border-black bg-[var(--base-color)] px-6 py-3 text-[16px] font-semibold uppercase text-black hover:border-[var(--base-color)] hover:bg-black hover:font-extrabold hover:text-[var(--base-color)];
  }

  .container-px {
    @apply mx-auto max-w-screen-xl px-8;
  }

  .home-section-container-lg {
    @apply mx-auto px-8 lg:px-20;
  }

  .home-section-container-sm {
    @apply mx-auto px-8 lg:px-40;
  }

  .nav-items {
    @apply min-w-fit cursor-pointer rounded-full border-2  border-solid border-transparent px-7 py-2 uppercase  hover:text-[var(--base-color)] focus:text-[var(--base-color)] active:text-[var(--base-color)];
  }

  .nav-items-active {
    @apply min-w-fit cursor-pointer rounded-lg border-2 border-solid border-[var(--base-color)] px-7 py-2 uppercase text-white  hover:bg-[var(--base-color)] hover:text-[var(--text-color)]  focus:bg-[var(--base-color)]  active:bg-[var(--base-color)] active:text-[var(--text-color)];
  }

  .bg-gradient-45 {
    @apply bg-gradient-to-tr from-red-800 to-transparent;
  }
}

.propertySidebar {
    background: color(50);
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    height: 100vh;
    box-shadow: 10px 10px 10px 10px rgba(color(900), .3);

    &.sidebar-lg {
        width: 450px;
    }

    .card {
        &-header {
            font-weight: bold;
            font-size: 15px;
        }

        box-shadow: none;
        border:1px solid rgba(color(900), .2);
        // box-shadow: 0px 6px 10px 0px rgba(color(900),.1);

    }

    @include media-breakpoint-down(sm) {
        width: 300px;

        &.sidebar-lg {
            width: 300px;
        }
    }
}
.react-tel-input {
    .form-control {
        width: 100%;
        background: transparent;
    }
}

.ltMobile {
    button {
        &.dropdown-toggle {
            img {
                height: 15px;
                width: 20px;
                object-fit: cover;
                margin-right: 10px;
            }

            display: flex;
            align-items: center;

            span {
                flex-grow: 1;
            }

            input[type="text"] {
                width: 100%;
                border-width: 0;
            }

            background: #fff;
            // border: 1px solid!important;
            color:#212529;
            box-shadow: none;
            width: 100%;
            text-align: left;
            font-size: 14px;
            min-height: calc(1.8125rem + 2px);
            padding: 0.375rem 0.75rem;
            border: 1px solid #ced4da !important;

            &:active,
            &:focus,
            &:hover {
                box-shadow: none !important;
                background: color(50) !important;
            }

        }

    }

    .dropdown {

        &-menu {
            max-height: 250px !important;
            overflow: auto;
            padding: 0 5px;

            input {
                width: 100%;
                border: 1px solid #ddd
            }
        }

        &-item {
            overflow: hidden;
            text-overflow: ellipsis;
            padding: .5rem .7rem;

            img {
                height: 15px;
                width: 20px;
                object-fit: cover;
                margin-right: 10px;
            }
        }
    }
}
.articlePage {
    // background-image: url("../../../../public/assets/images/arti");
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    background-size: 100%;
    background-color: #fcf3ea;

    &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        // background-image: url("./assets/images/job-detail-shade.png");
        background-size: 100% 100%;
        background-position: top center;
        z-index: -1;
    }

    .bannerSection {
        padding: 88px 0 92px;

        &_content {
            max-width: 782px;
            margin: 0 auto;
        }
    }

    .blog {
        .customRow {
            margin: 0 -12px;

            [class*="col-"] {
                padding: 0 12px;
            }
        }
    }

    .searchBar {
        &-group {
            height: calc(1.8125rem + 2px);
            margin-top: 25px;

            .form-control {
                padding-right: 37px;
            }
        }

        .btn {
            min-width: 103px;
            border-radius: 8px;
        }

        &-reset {
            top: 16px;
            right: 20px;
            font-size: 10px;
            color: color(850);
        }
    }

    .search-title {
        margin: 40px 0;

        h2 {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .loadMore {
        margin: 0 0 40px;

        .btn {
            min-width: 105px;
            padding: 8px 11px;

            &-lg {
                height: 48px;
                padding: 12px 25px;
            }

            em {
                font-size: 10px;
                margin-left: 10px;
            }
        }
    }

    .articleMain {
        p {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 24px;

        }

        .title-lg {
            font-size: 32px;
            line-height: 40px;
            margin: 56px 0 24px;
        }

        .title-md {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 12px;
        }

        .tags {
            margin: 35px 0 0;

            .badge {
                padding: 4px 8px;
                box-shadow: 0px 18px 88px -4px rgba(9, 10, 14, 0.14);
                border: 1px solid #e1e1e1;
                border-radius: 6px;
                background: color(900);
                font-size: 12px;
                color: color(50);
            }
        }

        &-Publice {
            span {
                color: color(800);

                &+span {
                    margin-left: 24px;
                }
            }
        }

        &-thumbnail {
            border: 4px solid color(50);
            overflow: hidden;
            margin: 25px 0 55px;
        }

        &-listing {
            margin: 24px 0 56px;
            padding: 24px;

            li {
                color: color(850);

                &+li {
                    padding-top: 10px;
                }
            }
        }

        &-shareLink {
            padding: 56px 0 60px;

            label {
                color: color(900);
                line-height: 24px;
            }

            ul {
                margin-top: 15px;
            }

            li {
                font-size: 19px;

                &:not(:last-child) {
                    margin-right: 20px;
                }

                a {
                    color: color(900);

                    &:hover {
                        color: color(500);
                    }
                }
            }
        }

        .description {
            
            &-txt {
                padding: 0 40px;
            }
        }
    }

    // relatedArt
    .relatedArt {
        padding: 60px 0;

        &-title {
            margin-bottom: 40px;

            h3 {
                font-size: 32px;
                line-height: 40px;
            }
        }

        @include media-breakpoint-down(xl) {
            &-title {
                margin-bottom: 32px;

                h3 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        .bannerSection {
            padding: 58px 0;

            &_content {
                max-width: 632px;
            }
        }

        .loadMore {
            margin: 0 0 32px;
        }

        .articleMain {
            p {
                font-size: 16px;
                line-height: 24px;
            }

            .title-lg {
                font-size: 24px;
                line-height: 30px;
            }

            .title-md {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .search-title {
            margin: 32px 0;

            h2 {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .bannerSection {
            padding: 70px 0;

            &_content {
                max-width: 100%;

                h1 {
                    margin-bottom: 24px;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .bannerSection {
            padding: 64px 0;

            &_content {
                h5 {
                    font-size: 16px;
                }
            }
        }

        .loadMore {
            margin: 0 0 24px;
        }

        .articleMain {
            &-Publice {
                span {
                    display: block;

                    &+span {
                        margin-left: 0;
                    }
                }
            }

            &-listing {
                margin: 24px 0 40px;
            }

            .title-lg {
                margin: 40px 0 24px;
            }

            .description {
                .commonHeading {
                    margin-bottom: 40px;
                }

                &-txt {
                    padding: 0;
                }
            }
        }

        .searchBar {
            .btn {
                padding: 0;
                font-size: 16px;
                min-width: 48px;
            }
        }
    }
}

.description_ {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 70px;
    line-height: 30px;
    img {
        display: none;
    }
    * {
        margin-bottom: 0px;
        font-weight: normal !important;
        font-size: 14px !important;
    }
}

.blogCard.card img {
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.articleSlider {
    padding: 0;
    margin: 0px -1.5rem;

    &_slide {
        position: relative;

        img {
            height: 700px;
            object-fit: cover;
            object-position: center;
        }
    }

    &_slideinfo {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(color(900), .4);
        p {
            color: color(50);
            font-size: 20px;
        }
        h3 {
            font-weight: 700;
            font-size: 44px;
            color: color(50);
        }

        .descriptions {
            img {
                max-width: 100%;
                height: auto !important;
                display: none;
            }
            * {
                color: color(50) !important;

            }
            font-size: 20px;
            color: color(50) !important;
            text-align: center;
            padding: 0 10%;
            line-height: 25px;
            margin-top: 0px;
            @include text-clamp($lines: 3, $line-height: false)
        }

    }

    @include media-breakpoint-down(md) {
        &_slide {
            img {
                height: 600px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &_slide {
            img {
                height: 500px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &_slide {
            img {
                height: 400px;
            }
        }
    }
}
.receiptCard {
    background: color(50);
    padding: 15px;
    background-color: color(50);
    position: relative;
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    border-color: #e9ebf2;

    .receiptBg {
        // background-image: url('../../images/triangle.png');
        height: 12px;
        width: 100%;
        position: absolute;
        left: 0;

        // background-color: color(100);
        &:after {
            content: "";
            position: absolute;
            background-image: url('../../images/triangle.png');
            bottom: -14px;
            left: 0;
            width: 100%;
            height: 12px;
            /* background: red; */
            background-size: 20px;
            z-index: 0;
            filter: invert(1) blur(3px);
            opacity: .1;
        }

        &:before {
            content: "";
            position: absolute;
            background-image: url('../../images/triangle.png');
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 12px;
            background-size: 20px;
            z-index: 1;
        }
    }
}

.bankSelection {
    position: relative;

    .list-group-item {
        cursor: pointer;

        img {
            height: 20px;
            width: 20px;
            object-fit: contain;
        }

        transition: all .3s;

        &:hover {
            background: color(100);
        }

        .list-group {
            position: absolute;
            z-index: 10;
            width: 100%;
            left: 0;
            top: 35px;
        }
    }
}

.bankcard {
    margin-bottom: 20px;

    &_logo {
        width: 70px;
        min-width: 70px;
        height: 70px;
        border-radius: 10px;
        border: 1px solid #cecece;
        overflow: hidden;
        padding: 5px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 7px;
        }
    }

    @include media-breakpoint-down(md) {
        &_logo {
            min-width: 50px;
            height: 50px;
        }
    }
}

.playerDeposit_modal {
    button.btn-close {
        position: relative;
        z-index: 100;
    }

    .modal-header {
        background: color(215);
        border-top: 2px solid !important;
    }
}

.profile_card {
    background: white;
    padding: 4.3rem 1.3rem 1.3rem;
    margin: -3.8rem -2.5rem;
}
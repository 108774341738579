.ltEditor {
    background: color(50);
    &-toolbar {
        display: flex;
        align-items: center;
    }
    &-toolgroup {
        padding: 10px 20px;
        margin-right: 10px;
    }
    &-btn {
        font-size: 1.4rem;
        padding: 5px;
        display: inline-block;
        cursor: pointer;
        &:hover {
            opacity: .5;
        }
    }


}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    min-height: 200px;
}
.ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
    z-index: var(--ck-z-modal);
    border-radius: .4rem .4rem 0 0!important;
}
.attendanceAction {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(110);
    margin: 0 5px;
    border-radius: 90px;
    font-size: 20px;
    font-weight: bold;

    &.present {
        background: color(600);
        color: color(50);
    }
    &.absence {
        background: color(350);
        color: color(50);
    }
    &.leave {
        background: color(450);
        color: color(900);
    }
}
